import { Component, ViewChild, ElementRef, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { AlertService, AuthenticationService, ErrorsService, WorkspaceService } from 'app/_services'
import { FormGroup, FormBuilder } from '@angular/forms';
import { ErrorGroup, IssueState, Workspace } from 'app/_models';
import { TagInputComponent } from 'ngx-chips';
import { TagModel } from 'ngx-chips/core/accessor';

@Component({
    selector: 'edit-error-group-modal',
    templateUrl: 'edit.error.group.modal.html'
})
export class EditErrorGroupModal implements OnInit {

    form: FormGroup
    existingApplicationVersions: string[]

    private workspace: Workspace

    private _errorGroup: ErrorGroup
    @Input()
    public set errorGroup(ErrorGroup: ErrorGroup) {
        this._errorGroup = ErrorGroup
        this.setupForm()
    }
    public get errorGroup(): ErrorGroup {
        return this._errorGroup
    }

    @Output() isClosingErrorGroup: boolean = false

    @Output() errorGroupUpdated = new EventEmitter()

    @ViewChild('closeButton') closeButton: ElementRef
    @ViewChild('tagInput') tagInput: TagInputComponent

    constructor(
        private alertService: AlertService,
        private formBuilder: FormBuilder,
        private errorsService: ErrorsService,
        private workspaceService: WorkspaceService,
        private authenticationService: AuthenticationService,
    ) {
    }

    ngOnInit() {
        this.workspace = this.authenticationService.currentWorkspaceValue

        let weakThis = this
        $('#editErrorGroupModal').on('show.bs.modal', function () {
            weakThis.reloadApplicationVersionsIfNeeded()
        })

        this.setupForm()
    }

    saveErrorGroup() {
        let newErrorGroupState: IssueState = this.isClosingErrorGroup ? IssueState.Closed : this.errorGroup.state

        // If tag was submitted (by pressing tab or space) then we need to read its value from TagModel[], otherwise fall back to raw formValue
        let fixVersionTagModels = this.form.controls.fixVersion.value as TagModel[]
        let newErrorGroupFixVersion = fixVersionTagModels.length > 0 ? (fixVersionTagModels[0] as any).value : (this.tagInput ? this.tagInput.formValue : null)

        this.errorsService.updateErrorGroup(this.errorGroup.id, this.form.controls.annotation.value, newErrorGroupState, newErrorGroupFixVersion).then((response) => {
            this.closeButton.nativeElement.click()
            this.errorGroupUpdated.emit()

        }).catch((error) => {
            this.alertService.handleError(error)
        })
    }

    private setupForm() {
        let fixVersionTags: TagModel[] = []
        if (this.errorGroup && this.errorGroup.fixVersion) {
            let tagModel = { value: this.errorGroup.fixVersion, display: this.errorGroup.fixVersion }
            fixVersionTags = [tagModel]
        }

        this.form = this.formBuilder.group({
            annotation: [this.errorGroup ? this.errorGroup.annotation : null],
            fixVersion: [fixVersionTags]
        })
    }

    private reloadApplicationVersionsIfNeeded() {
        if (this.existingApplicationVersions || !this.isClosingErrorGroup) { return }

        this.workspaceService.getWorkspaceApplicationVersions(this.workspace.id).then((response) => {
            this.existingApplicationVersions = response.data
        })
    }

}
