<div id="editCrashGroupModal" class="modal fade">
    <div class="modal-dialog" *ngIf="crashGroup">
        <div class="modal-content">

            <div class="modal-header">
                <h5 class="modal-title" *ngIf="isClosingCrashGroup == false">Crash group #{{crashGroup.serialNumber}}</h5>
                <h5 class="modal-title" *ngIf="isClosingCrashGroup == true">Close crash group #{{crashGroup.serialNumber}}?</h5>
                <button #closeButton type="button" class="close" data-dismiss="modal">&times;</button>
            </div>

            <div class="modal-body">
                <form [formGroup]="form">
                    <div class="form-group" *ngIf="isClosingCrashGroup == false">
                        <div class="font-size-xs text-uppercase text-muted mb-1">Annotation</div>
                        <textarea id="annotation" formControlName="annotation" rows="4" cols="4" placeholder="Add annotation" class="form-control"></textarea>
                        <div class="text-muted mt-1">Attach useful contextual information related to this crash group. This may include links to your issue tracking system such as JIRA or Trello. <code>Markdown</code> syntax is supported.</div>
                    </div>

                    <div class="form-group" *ngIf="isClosingCrashGroup == true">
                        <div class="font-size-xs text-uppercase text-muted mb-1">Fix version</div>
                        <tag-input #tagInput style="height: auto;" formControlName="fixVersion" placeholder='+ Add a fix version' secondaryPlaceholder="+ Add a fix version" [onTextChangeDebounce]="0" [maxItems]='1' [separatorKeyCodes]="[9, 44, 32, 188]" [animationDuration]="{enter: '0ms', leave: '0ms'}" theme='minimal' class="form-control pl-1 py-0">
                            <tag-input-dropdown [autocompleteItems]="existingApplicationVersions"></tag-input-dropdown>
                        </tag-input>
                        <div class="text-muted mt-1">Add an optional fix version, and the crash group will automatically reopen if new crashes are detected starting from this version.</div>
                    </div>
                </form>
            </div>

            <div class="modal-footer">
                <button (click)="saveCrashGroup()" [disabled]="form.invalid" type="button" class="btn bg-primary-400" *ngIf="isClosingCrashGroup == false">Save</button>
                <button (click)="saveCrashGroup()" [disabled]="form.invalid" type="button" class="btn bg-primary-400" *ngIf="isClosingCrashGroup == true">Close</button>
            </div>
        </div>
    </div>
</div>
