import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Application, CrashGroup, CrashReport, IssueState, CallStack, CrashTrendsResponse, CrashTrendsSummaryResponse, CrashFilter, DateRange } from 'app/_models';
import { AlertService, CrashesService, ApplicationService } from 'app/_services';
import { ActivatedRoute, Router } from '@angular/router';
import { ApplicationFormatter, DateFormatter, IssueStateFormatter } from 'app/_helpers';
import { Subscription } from 'rxjs';
import { Title } from '@angular/platform-browser';
import { EditCrashGroupModal } from './edit.crash.group.modal/edit.crash.group.modal';
import * as moment from 'moment';

@Component({
    templateUrl: 'crash.group.component.html',
    styleUrls: [
        'crash.group.component.css'
    ]
})
export class CrashGroupComponent implements OnInit, OnDestroy {

    IssueState = IssueState

    application: Application
    crashGroup: CrashGroup
    crashReports: CrashReport[]
    callStack: CallStack
    crashTrendsResponse: CrashTrendsResponse
    crashTrendsSummaryResponse: CrashTrendsSummaryResponse
    stackTraceVisible = false
    didFinishLoading = false

    selectedDateRange: DateRange

    @ViewChild('editCrashGroupModal') editCrashGroupModal: EditCrashGroupModal

    private queryParamsSubscription: Subscription
    private routeParamsSubscription: Subscription
    private currentApplicationSubscription: Subscription

    constructor(
        public dateFormatter: DateFormatter,
        public issueStateFormatter: IssueStateFormatter,
        private crashesService: CrashesService,
        private route: ActivatedRoute,
        private router: Router,
        private applicationService: ApplicationService,
        private alertService: AlertService,
        private applicationFormatter: ApplicationFormatter,
        private titleService: Title
    ) {
    }

    ngOnInit() {
        let query = this.route.snapshot.queryParams
        this.selectedDateRange = DateRange.fromParams(query) ?? DateRange.lastMonth()

        this.queryParamsSubscription = this.route.queryParams.subscribe(params => {

            // In case time filter changed, or any other filter, refresh everything
            this.selectedDateRange = DateRange.fromParams(params) ?? DateRange.lastMonth()
            this.reloadCrashReportsAndDashboard()
        })

        this.routeParamsSubscription = this.route.params.subscribe(params => {
            this.reloadCrashGroup()
        })

        this.currentApplicationSubscription = this.applicationService.currentApplication.subscribe((application) => {
            this.application = application
            if (this.application != null) {
                this.reloadCrashGroup()
            }
        })
    }

    ngOnDestroy() {
        this.currentApplicationSubscription.unsubscribe()
        this.queryParamsSubscription.unsubscribe()
        this.routeParamsSubscription.unsubscribe()
    }

    refreshCrashTrends() {
        if (!this.application) { return }

        // Will also switch the trends chart into loading mode
        this.crashTrendsResponse = null

        let crashFilter = this.makeCurrentCrashFilter()
        this.crashesService.getCrashTrends(this.application.workspaceId, crashFilter).then((response) => {
            this.crashTrendsResponse = response
            this.selectedDateRange = response.dateRange

            this.crashesService.getCrashTrendsSummary(this.application.workspaceId, crashFilter).then((response) => {
                this.crashTrendsSummaryResponse = response
            })

        }).catch((error) => {
            this.alertService.handleError(error)
        })
    }

    onDateRangeChanged(dateRange: DateRange) {
        this.router.navigate([], { queryParams: { startDate: moment(dateRange.startDate).toISOString(), endDate: moment(dateRange.endDate).toISOString() }, queryParamsHandling: 'merge' })
    }

    onShowStackTraceClick() {
        this.stackTraceVisible = !this.stackTraceVisible
    }

    onAnnotateCrashGroupClick() {
        this.editCrashGroupModal.isClosingCrashGroup = false

        let quickJumpModal: any = $('#editCrashGroupModal')
        quickJumpModal.modal('show')
    }

    reloadCrashGroup() {
        if (!this.application) { return }

        let crashGroupSlug = this.route.snapshot.params['crashGroupSlug']
        this.titleService.setTitle(`${this.applicationFormatter.displayName(this.application)} | Crash Group #${crashGroupSlug}`)

        this.crashesService.getWorkspaceCrashGroupWithSlug(this.application.workspaceId, crashGroupSlug).then((response) => {
            this.crashGroup = response.data
            this.reloadCrashReportsAndDashboard()

        }).catch((error) => {
            this.alertService.handleError(error)
        })
    }

    reloadCrashReportsAndDashboard() {
        if (!this.crashGroup) { return }

        this.callStack = null
        this.crashesService.getCrashGroupLatestCallStack(this.crashGroup.id).then((result) => {
            this.callStack = result
        }).catch((error) => {
            this.alertService.handleError(error)
        })

        this.crashReports = null

        let crashFilter = this.makeCurrentCrashFilter()
        this.crashesService.getCrashGroupCrashReports(this.crashGroup.id, crashFilter).then((result) => {
            this.crashReports = result.data
            this.didFinishLoading = true

        }).catch((error) => {
            this.alertService.handleError(error)
        })

        this.refreshCrashTrends()
    }

    onCrashGroupUpdated() {
        this.reloadCrashGroup()
    }

    updateIssueState(newState: IssueState) {
        if (this.crashGroup.state == newState) { return }

        if (newState != IssueState.Closed) {
            this.saveIssueState(newState, this.crashGroup.fixVersion)
            return
        }

        this.editCrashGroupModal.isClosingCrashGroup = true

        let quickJumpModal: any = $('#editCrashGroupModal')
        quickJumpModal.modal('show')
    }

    navigateToCrashReport(crashReport: CrashReport) {
        this.router.navigate(['crash-reports', crashReport.serialNumber], { relativeTo: this.route })
    }

    private saveIssueState(newState: IssueState, fixVersion: string | null) {
        this.crashesService.updateCrashGroup(this.crashGroup.id, this.crashGroup.annotation, newState, fixVersion).then((response) => {
            this.crashGroup = response.data

        }).catch((error) => {
            this.alertService.handleError(error)
        })
    }

    private makeCurrentCrashFilter(): CrashFilter {
        let crashFilter = new CrashFilter()
        crashFilter.applicationId = this.application.id
        crashFilter.crashGroupId = this.crashGroup.id
        crashFilter.startDate = moment(this.selectedDateRange.startDate).toDate()
        crashFilter.endDate = moment(this.selectedDateRange.endDate).toDate()

        return crashFilter
    }

}
