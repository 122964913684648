<div class="content-wrapper" id="pageContent">

    <div class="content">
        <div class="row">
            <div class="col-md-12">
                <alert></alert>
            </div>
        </div>

        <div class="justify-content-center text-muted d-flex w-100 align-items-center" style="height: 90vh" *ngIf="!didFinishLoading">
            <div class="align-self-center">
                <i class="icon-spinner4 spinner mr-2"></i> Loading...
            </div>
        </div>

        <div class="row" *ngIf="didFinishLoading">
            <div class="col-md-12">
                <div class="card mb-1">
                    <div class="page-header border-bottom-light">
                        <div class="page-header-content header-elements-md-inline">
                            <div class="page-title">
                                <h1 class="font-weight-thin">
                                    <a [routerLink]="['../../']" class="breadcrumb-item">{{application.name}}</a>
                                    <a [routerLink]="['../']" class="breadcrumb-item">Crash Groups</a>
                                    <span class="breadcrumb-item active">#{{crashGroup.serialNumber}}</span>
                                </h1>
                                <div class="mb-0" *ngIf="crashTrendsSummaryResponse">
                                    <!-- <span class="badge badge-light badge-pill mr-1"><count [count]="crashTrendsSummaryResponse.totalCrashesCount"></count> reports</span>
                                    <span class="badge badge-light badge-pill mr-1"><count [count]="crashTrendsSummaryResponse.totalDevicesCount"></count> users</span> -->
                                    <!-- <span class="badge badge-light badge-pill mr-1">App versions: {{crashGroup.affectedVersions}}</span> -->
                                    <span class="badge badge-light badge-pill mr-1">Latest report: {{dateFormatter.timeAgoShort(crashTrendsSummaryResponse.latestCrashDate)}}</span>
                                    <span class="badge badge-light badge-pill mr-1" *ngIf="crashGroup.fixVersion">Marked as fixed in: <span class="font-weight-semibold">{{crashGroup.fixVersion}}</span></span>
                                </div>
                                <div class="mb-0" *ngIf="crashTrendsSummaryResponse == null">
                                    <span class="badge badge-light">Loading...</span>
                                </div>
                            </div>

                            <div class="header-elements d-flex">
                                <div class="dropdown">
                                    <button type="button" class="btn btn-outline bg-primary border-primary text-primary-800 btn-icon dropdown-toggle ml-2" data-toggle="dropdown">
                                        {{issueStateFormatter.displayName(crashGroup.state)}}
                                    </button>
                                    <div class="dropdown-menu dropdown-menu-right">
                                        <a href="javascript:void(0)" (click)="updateIssueState(IssueState.Open)" class="dropdown-item" [ngClass]="{ 'active' : crashGroup.state == 1 }">{{issueStateFormatter.displayName(IssueState.Open)}}</a>
                                        <a href="javascript:void(0)" (click)="updateIssueState(IssueState.Closed)" class="dropdown-item d-block" [ngClass]="{ 'active' : crashGroup.state == 0 }">
                                            <ng-container *ngIf="crashGroup.state == IssueState.Closed && crashGroup.fixVersion != null">Fixed in: {{crashGroup.fixVersion}}</ng-container>
                                            <ng-container *ngIf="crashGroup.state != IssueState.Closed || crashGroup.fixVersion == null">{{issueStateFormatter.displayName(IssueState.Closed)}}</ng-container>
                                        </a>
                                        <a href="javascript:void(0)" (click)="updateIssueState(IssueState.Ignored)" class="dropdown-item" [ngClass]="{ 'active' : crashGroup.state == 2 }">{{issueStateFormatter.displayName(IssueState.Ignored)}}</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="card-body">
                        <h5 class="mb-0 pb-1">Notes</h5>

                        <ng-container *ngIf="crashGroup.annotation">
                            <markdown [content]="crashGroup.annotation"></markdown>
                            <ul class="list-inline list-inline-dotted list-inline-condensed mt-1 mb-0">
                                <li class="list-inline-item"><a href="javascript:void(0)" (click)="onAnnotateCrashGroupClick()" class="font-weight-semibold">Edit annotation</a></li>
                                <li class="list-inline-item"><span class="text-muted font-size-sm font-weight-light">Updated {{dateFormatter.timeAgo(crashGroup.annotationUpdateDate)}}</span></li>
                            </ul>
                        </ng-container>

                        <ng-container *ngIf="crashGroup.annotation == null">
                            <a href="javascript:void(0)" (click)="onAnnotateCrashGroupClick()" class="font-weight-semibold">Annotate this crash group</a>
                            <span class="text-muted"> with contextual information and links to an issue tracking system like JIRA or Trello.</span>
                        </ng-container>
                    </div>

                    <!-- EXCEPTION -->
                    <div class="card-body">
                        <h5 class="mb-0 pb-1 mr-2">Exception</h5>
                        <div class="align-items-baseline">
                            <span class="font-weight-semibold">{{crashGroup.exceptionSymbol}}</span>
                            <span class="text-muted ml-1">{{crashGroup.sourceCodeLocation}}</span>
                        </div>
                        <div class="text-muted">
                            <span *ngIf="crashGroup.exceptionName">{{crashGroup.exceptionName}}</span>
                            <span *ngIf="crashGroup.exceptionName && crashGroup.exceptionMessage">:</span>
                            {{crashGroup.exceptionMessage}}
                        </div>

                        <a href="javascript:void(0)" (click)="onShowStackTraceClick()" class="font-weight-semibold" *ngIf="!stackTraceVisible">Show latest stack trace</a>
                        <call-stack [callStack]="callStack" [crashedSymbol]="crashGroup.exceptionSymbol" *ngIf="callStack && stackTraceVisible"></call-stack>
                        <a href="javascript:void(0)" (click)="onShowStackTraceClick()" class="font-weight-semibold" *ngIf="stackTraceVisible">Hide stack trace</a>
                    </div>

                    <!-- TIME FILTER -->
                    <div class="card-body d-flex align-items-center align-items-baseline">
                        <h5 class="mb-0 pb-0">Trends</h5>

                        <ul class="ml-auto pagination">
                            <li class="page-item active">
                                <date-range [dateRange]="this.selectedDateRange" (onDateRangeChange)="onDateRangeChanged($event)"></date-range>
                            </li>
                        </ul>
                    </div>

                    <!-- TRENDS -->
                    <div class="card-body py-0 my-0">
                        <!-- <h5 class="mb-0 pb-0">Trends</h5> -->

                        <!-- TRENDS LOADING -->
                        <div class="justify-content-center text-muted d-flex w-100 align-items-center" style="min-height: 280px" *ngIf="!crashTrendsResponse || !crashTrendsSummaryResponse">
                            <div class="align-self-center">
                                <i class="icon-spinner4 spinner mr-2"></i> Loading trends...
                            </div>
                        </div>

                        <!-- TRENDS LOADED -->
                        <div class="d-flex" *ngIf="crashTrendsResponse && crashTrendsSummaryResponse">

                            <!-- TRENDS -->
                            <div style="width: calc(100vw - 342px); margin-top: 20px; margin-bottom: 20px;">
                                <crashes-over-time-chart [datePoints]="crashTrendsResponse.datePoints" [crashCounts]="crashTrendsResponse.crashCounts"></crashes-over-time-chart>
                            </div>

                            <!-- SUMMARY -->
                            <div class="border-left-light pl-3" style="padding-top: 20px;">
                                <h5 class="mb-0 pb-3">Summary</h5>

                                <div style="width: 180px;">
                                    <crash-trends-summary [crashTrendsSummaryResponse]="crashTrendsSummaryResponse"></crash-trends-summary>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- CRASH REPORTS LIST -->
                    <table class="table" *ngIf="crashReports && crashReports.length > 0">
                        <thead>
                            <tr role="row">
                                <th class="border-bottom-0 nowrap w-100">Crash reports</th>
                                <th class="border-bottom-0 nowrap text-right">App version</th>
                                <th class="border-bottom-0 nowrap text-center">OS</th>
                                <th class="border-bottom-0 nowrap text-center">Device</th>
                                <th class="border-bottom-0 nowrap text-center">Locale</th>
                                <th class="border-bottom-0 nowrap text-right">Attachment</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let crashReport of crashReports" (click)="navigateToCrashReport(crashReport)">
                                <td class="d-flex">
                                    <div class="mr-2">
                                        <span class="badge badge-mark border-pink-400"></span>
                                    </div>
                                    <div>
                                        <a class="font-weight-semibold button-muted nowrap" [routerLink]="['crash-reports/', crashReport.serialNumber]">
                                            #{{crashReport.serialNumber}}
                                        </a>
                                        <div class="text-muted breakall">{{dateFormatter.dateTimeShort(crashReport.crashDate)}}</div>
                                    </div>
                                </td>
                                <td class="nowrap text-center">
                                    <span class="badge badge-light badge-pill">{{crashReport.appVersion}}</span>
                                </td>
                                <td class="nowrap text-center">{{crashReport.system}}</td>
                                <td class="nowrap text-center">{{crashReport.deviceModelName}}</td>
                                <td class="nowrap text-center">{{crashReport.locale}}</td>
                                <td class="text-right" [ngClass]="{ 'text-muted' : crashReport.attachment == null }">
                                    <i class="icon-attachment" *ngIf="crashReport.attachment != null"></i>
                                    <span class="text-muted" *ngIf="crashReport.attachment == null">-</span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

    </div>
</div>

<edit-crash-group-modal #editCrashGroupModal [crashGroup]="this.crashGroup" (crashGroupUpdated)="onCrashGroupUpdated()"></edit-crash-group-modal>
